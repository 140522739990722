<footer class="page-footer mdb-color center-on-small-only">
  <div class="container">
    <div class="row">
      <div class="col-md-5 offset-md-2">
        <h5 class="title">1. PF Finance, s.r.o.</h5>
        <ul>
          <li>IČ: 09373292</li>
          <li>DIČ: CZ09373292</li>
        </ul>
      </div>
      <div class="col-md-2">
        <h5 class="title">{{'contact' | translate}}</h5>
        <ul>
          <li><a href="mailto:info@1pf.cz">email: info@1pf.cz</a></li>
          <li>tel: +420 603 449 233</li>
        </ul>
      </div>

      <div class="col-md-2">
        <h5 class="title">{{'adress' | translate}}</h5>
        <ul>
          <li>Služská 1865/15, Praha 8, 182 00</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <hr>
        <a  href="assets/reklamacni_rad.pdf">Reklamační řád</a>
      </div>
    </div>
  </div>
  <hr>
  <div class="text-center mb-3">
    <a class="btn-floating btn-small" href="https://www.facebook.com/1-PF-sro-121558997876654/" target="_blank"
      rel=“noopener“ aria-label="facebook"><i class="fab fa-facebook-f fa-lg white-text mr-3"> </i></a>
    <a class="btn-floating btn-small" href="https://www.linkedin.com/company/1-pf/" target="_blank" rel=“noopener“
      aria-label="linkedin"><i class="fab fa-linkedin-in fa-lg white-text mr-3"> </i></a>
  </div>
  <div class="footer-copyright">
    <div class="container-fluid">
      © 2020 1. PF Finance, s.r.o.
    </div>
  </div>
</footer>
