<div class="container contact-form" *ngIf="!subscribeSuccesfull">
  <form novalidate [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <h4 class="h4 text-center mb-4">{{'form-contact-us' | translate}}</h4>
    <div class="md-form">
      <i class="fa fa-user prefix grey-text"></i>
      <input class="form-control" formControlName="name" id="name" type="text" mdbInput mdbValidate>
      <label for="name">{{'form-name' | translate}}</label>
      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
        <div *ngIf="name.errors.required">
          {{'required' | translate}}
        </div>
      </div>
    </div>
    <div class="md-form">
      <i class="fa fa-envelope prefix grey-text"></i>
      <input class="form-control" formControlName="email" id="email" type="email" mdbInput mdbValidate>
      <label for="email">{{'form-email' | translate}}</label>
      <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
        <div *ngIf="email.errors.required">
          {{'required' | translate}}
        </div>
        <div *ngIf="email.errors.email">
          {{'emailFormat' | translate}}
        </div>
      </div>
    </div>
    <div class="md-form">
      <i class="fa fa-tag prefix grey-text"></i>
      <input class="form-control" formControlName="subject" id="subject" type="text" mdbInput mdbValidate>
      <label for="subject">{{'form-subject'| translate}}</label>
    </div>
    <div class="md-form">
      <i class="fa fa-pencil-alt prefix grey-text"></i>
      <textarea class="md-textarea" formControlName="message" id="message" style="height: 100px" type="text" mdbInput
        mdbValidate></textarea>
      <label for="message">{{'form-message' | translate}}</label>
      <div *ngIf="message.invalid && (message.dirty || message.touched)" class="alert alert-danger">
        <div *ngIf="message.errors.required">
          {{'required' | translate}}
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-red waves-light" type="submit" [disabled]="!contactForm.valid">{{'form-send' | translate}}
        <i class="far fa-paper-plane ml-1"></i>
      </button>
    </div>
  </form>
  <div class="col-sm-12" *ngIf="lazyLoad">
    <iframe allowfullscreen frameborder="0" height="450" title="location"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.977810492925!2d14.44711701571959!3d50.12413717943171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470beb38a9e18583%3A0x5494ccef784813d1!2zU2x1xb5za8OhIDE4NjUvMTUsIDE4MiAwMCBQcmFoYSA4!5e0!3m2!1scs!2scz!4v1521567153186"
      style="border:0" width="100%"></iframe>
  </div>
</div>
<div class="container contact-form" *ngIf="subscribeSuccesfull">
  <div class="row columns">
    <h3>{{'form-thanks' | translate }}</h3>
  </div>
</div>
