import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  selectedLang = 'cs';

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'cs']);
    if (sessionStorage.getItem('acceptLang') === 'en-US') {
      translate.use('en');
      translate.setDefaultLang('en');
      this.selectedLang = 'en';
    } else {
      translate.use('cs');
      translate.setDefaultLang('cs');
      this.selectedLang = 'cs';
    }
   }

  ngOnInit() {
  }

  selectLang(lang: string) {
    this.selectedLang = lang;
    switch (this.selectedLang) {
      case 'cs':
        sessionStorage.setItem('acceptLang', 'cs-CZ');
        this.translate.use('cs');
        break;
      case 'en':
        sessionStorage.setItem('acceptLang', 'en-US');
        this.translate.use('en');
        break;
    }
  }
}
