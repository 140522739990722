<div class="container">
  <div class="ml-auto mr-auto">
    <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/snidane2018.jpg'" alt="event">
        </div>
        <div class="carousel-caption">
          <h4>{{'carousel.breakfast-h' | translate}}</h4>
          <p>{{'carousel.breakfast-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/hory2018_2.jpg'" alt="event">
        </div>
        <div class="carousel-caption">
          <h4>{{'carousel.kaprun-h' | translate}}</h4>
          <p>{{'carousel.kaprun-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/beh2017.jpg'" alt="event">
        </div>
        <div class="carousel-caption">
          <h4>{{'carousel.run-2017-h' | translate}}</h4>
          <p>{{'carousel.run-2017-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/Voda2016.jpg'" alt="event">
        </div>

        <div class="carousel-caption">
          <h4>{{'carousel.sazava-h' | translate}}</h4>
          <p>{{'carousel.sazava-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/pfbeh.jpg'" alt="event">
        </div>

        <div class="carousel-caption">
          <h4>{{'carousel.run-h' | translate}}</h4>
          <p>{{'carousel.run-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/eg2015.jpg'" alt="event">
        </div>

        <div class="carousel-caption">
          <h4>{{'carousel.belfry-h' | translate}}</h4>
          <p>{{'carousel.belfry-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/drone2015.jpg'" alt="event">
        </div>

        <div class="carousel-caption">
          <h4>{{'carousel.drone-h' | translate}}</h4>
          <p>{{'carousel.drone-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/tux2014.jpg'" alt="event">
        </div>

        <div class="carousel-caption">
          <h4>{{'carousel.hintertux-h' | translate}}</h4>
          <p>{{'carousel.hintertux-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/kolaudace2014.jpg'" alt="event">
        </div>
        <div class="carousel-caption">
          <h4>{{'carousel.kolaudace-h' | translate}}</h4>
          <p>{{'carousel.kolaudace-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/totesGebirge2014.jpg'" alt="event">
        </div>
        <div class="carousel-caption">
          <h4>{{'carousel.gebirge-h' | translate}}</h4>
          <p>{{'carousel.gebirge-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/lyze2013.jpg'" alt="event">
        </div>

        <div class="carousel-caption">
          <h4>{{'carousel.christmas-h' | translate}}</h4>
          <p>{{'carousel.christmas-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/rohace2013.jpg'" alt="event">
        </div>

        <div class="carousel-caption">
          <h4>{{'carousel.top-h' | translate}}</h4>
          <p>{{'carousel.top-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/zadar2013.jpg'" alt="event">
        </div>
        <div class="carousel-caption">
          <h4>{{'carousel.zadar-h' | translate}}</h4>
          <p>{{'carousel.zadar-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/support.jpg'" alt="event">
        </div>
        <div class="carousel-caption">
          <h4>{{'carousel.inet-h' | translate}}</h4>
          <p>{{'carousel.inet-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="ml-auto mr-auto" [lazyLoad]="'assets/news/kost2013.jpg'" alt="event">
        </div>
        <div class="carousel-caption">
          <h4>{{'carousel.kost-h' | translate}}</h4>
          <p>{{'carousel.kost-p' | translate}}</p>
        </div>
      </mdb-carousel-item>
    </mdb-carousel>
  </div>
</div>
