<section class="reference" id="reference">
  <div class="reference-img" [lazyLoad]="'/assets/reference-header.jpg'">
    <div class="headline-inner">
      <h2>{{'reference-headline' | translate}}</h2>
    </div>
  </div>
  <div class="container reference-header">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <h2>Reference</h2>
      </div>
      <div class="col-md-8 col-sm-12">
        <p>{{'reference-p' | translate}}</p>
      </div>
    </div>
    <div class="icon-down">
      <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1"
        style="enable-background:new 0 0 47.2 27.1;" version="1.1" viewBox="0 0 47.2 27.1" x="0px"
        xmlns="http://www.w3.org/2000/svg" y="0px">
        <style type="text/css">
          .st0 {
            fill: #E91F26;
          }

        </style>
        <polygon class="st0" points="23.6,27.1 0,3.5 3.5,0 23.6,20 43.6,0 47.2,3.5 "></polygon>
      </svg>
    </div>
  </div>
  <div class="reference-content container">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-12">
          <img alt="reference" class="reference-logo" src="assets/ceska-sporitelna-logo.svg">
          <p class="reference-label">Česká spořitelna, a.s.</p>
          <p>{{'cs-ref' | translate}}</p>
        </div>
        <div class="col-sm-12">
          <img alt="reference" class="reference-logo" src="assets/eg-logo.gif">
          <p class="reference-label">eg solutions plc</p>
          <p>{{'eg-ref' | translate}}</p>
        </div>
        <div class="col-sm-12">
          <img alt="reference" class="reference-logo" src="assets/merck.gif">
          <p class="reference-label">Merck &amp; Co., Inc.</p>
          <p>{{'merc-ref' | translate}}</p>
        </div>
        <div class="col-sm-12">
          <img alt="reference" class="reference-logo" src="assets/mb-logo.gif">
          <p class="reference-label">Mercedes-Benz Česká republika s.r.o.</p>
          <p>{{'mb-ref' | translate}}</p>
        </div>
        <div class="col-sm-6">
          <div class="logo-box">
            <img alt="reference" class="reference-logo" src="assets/abf.gif">
            <p class="reference-label">ABF a.s.</p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="logo-box">
            <img alt="reference" class="reference-logo" src="assets/jnj.gif">
            <p class="reference-label">Johnson &amp; Johnson, s.r.o.</p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="logo-box">
            <img alt="reference" class="reference-logo" src="assets/renault.gif">
            <p class="reference-label">Renault Česká republika, a.s.</p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="logo-box">
            <img alt="reference" class="reference-logo" src="assets/r_db.gif">
            <p class="reference-label">Dresdner Bank, AG</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
