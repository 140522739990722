<section class="career" id="career">
  <div class="career-img" [lazyLoad]="'/assets/news/beh2017.jpg'">
    <div class="headline-inner">
      <h2>{{'career-headline' | translate}}</h2>
    </div>
  </div>
  <div class="container career-positions">
    <p>{{'home-contact-p1' | translate}}</p>
    <p>{{'home-contact-p2' | translate}}</p>
    <ul>
      <li>{{'position-1' | translate}}</li>
      <li>{{'position-2' | translate}}</li>
      <li>{{'position-3' | translate}}</li>
    </ul>
  </div>
  <app-contact-form></app-contact-form>
</section>
<section class="news" id="news">
  <div class="container news-content">
    <div class="row">
      <div class="col-md-5 col-sm-12">
        <h2>{{'home-news' | translate}}</h2>
      </div>
      <div class="col-md-7 col-sm-12">
        <p>{{'home-news-p' | translate}}</p>
      </div>
    </div>
  </div>
  <app-carousel></app-carousel>
</section>
