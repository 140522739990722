<section class="about" id="about">
  <div class="about-img" [lazyLoad]="'/assets/reference-header.jpg'">
    <!-- <img alt="header" [lazyLoad]="'/assets/about.jpg'"> -->
    <div class="headline-inner">
      <h2>{{'home-headline' | translate}} </h2>
    </div>
  </div>
  <div class="container about-content">
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <h2 class="about-us">{{'home-about'| translate}}</h2>
      </div>
      <div class="col-md-9 col-sm-12">
        <p>{{'home-about-p1' | translate}}</p>
        <p>{{'home-about-p2' | translate}}</p>


      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12 mobile">
        <h2 class="about-pf">1.PF</h2>
      </div>
      <div class="col-md-12 col-sm-12">
        <p>{{'home-about-p3' | translate}}</p>
        <p>{{'home-about-p4' | translate}}</p>
      </div>
      <!--div class="col-md-5 col-sm-12 desktop">
        <h2 class="about-pf">Použití</h2>
      </div-->
    </div>
    <!--div class="row">
      <div class="col-md-3 col-sm-12 mobile">
        <h2 class="about-pf">1.PF</h2>
      </div>
      <div class="col-md-12 col-sm-12">
        <p>{{'home-pf-p1' | translate}}</p>
        <p>{{'home-pf-p2' | translate}}</p>
      </div>
      <div class="col-md-5 col-sm-12 desktop">
        <h2 class="about-pf">Použití</h2>
      </div>
    </div-->
  </div>
</section>
<section class="services" id="services">
  <div class="container services-headline">
    <div class="row">
      <div class="col-md-4 col-sm-12 mobile">
        <h2 class="about-pf ">{{'home-services' | translate}}</h2>

      </div>
      <div class="col-md-8 col-sm-12">
        <p>{{'home-services-p1' | translate}}</p>

      </div>
      <div class="col-md-4 col-sm-12 desktop">
        <h2 class="about-pf desktop">{{'home-services' | translate}}</h2>

      </div>



    </div>
  </div>

</section>


<section class="contact" id="contact">
  <div class="container contact-content">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <h2>{{'contact' | translate}}</h2>
      </div>
      <div class="col-md-8 col-sm-12">
        <p>info@1pf.cz, +420 603 449 233</p>
        <p>Služská 1865/15, Praha 8, 182 00 , IČ: 09373292, DIČ: CZ09373292</p>
      </div>
    </div>
  </div>
  <div class="contact-img">
    <img alt="contact" class="img-fluid" [lazyLoad]="'assets/contact.jpg'">
  </div>

</section>

