<section class="services" id="services">
  <div class="services-img" [lazyLoad]="'/assets/services-header.jpg'">
    <div class="headline-inner">
      <h2>{{'services-headline' | translate}}</h2>
    </div>
  </div>
  <div class="container services-headline">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <h2>{{'services-offer' | translate}}</h2>
      </div>
      <div class="col-md-8 col-sm-12">
        <p>{{'services-p' | translate}}</p>
      </div>
    </div>
    <div class="icon-down">
      <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1"
        style="enable-background:new 0 0 47.2 27.1;" version="1.1" viewBox="0 0 47.2 27.1" x="0px"
        xmlns="http://www.w3.org/2000/svg" y="0px">
        <style type="text/css">
          .st0 {
            fill: #E91F26;
          }

        </style>
        <polygon class="st0" points="23.6,27.1 0,3.5 3.5,0 23.6,20 43.6,0 47.2,3.5 "></polygon>
      </svg>
    </div>
  </div>
  <div class="services-content">
    <div class="row">
      <div class="col-sm-12">
        <p>{{'services-content-p1' | translate}}</p>
        <p>{{'services-content-p2' | translate}}</p>
        <p>{{'services-content-p3' | translate}}</p>
      </div>
    </div>
    <div>
      <h3>{{'services-list' | translate}}</h3>
      <div class="row areas">
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-wa.svg'">
          <p class="service-label">{{'service-1' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-api.svg'">
          <p class="service-label">{{'service-2' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-ma.svg'">
          <p class="service-label">{{'service-3' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-be.svg'">
          <p class="service-label">{{'service-4' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-db.svg'">
          <p class="service-label">{{'service-5' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-sys.svg'">
          <p class="service-label">{{'service-6' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-kom.svg'">
          <p class="service-label">{{'service-7' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-spec.svg'">
          <p class="service-label">{{'service-8' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-4">
          <img alt="service" [lazyLoad]="'assets/ico-sup.svg'" style="height:60px; margin-top:10px">
          <p class="service-label">{{'service-9' | translate}}</p>
        </div>
        <div class="col-sm-6 col-md-12">
          <img alt="service" [lazyLoad]="'assets/ico-test.svg'" style="height:60px">
          <p class="service-label">{{'service-10' | translate}}</p>
        </div>
      </div>
    </div>
    <div>
      <p>{{'services-licence' | translate}}</p>
    </div>
  </div>

</section>
