import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactFormService } from '../core/contact-form.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit{
  public lazyLoad = false;
  contactForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    subject: new FormControl(null),
    message: new FormControl(null, [Validators.required]),
  });
  subscribeSuccesfull = false;

  constructor(private contactFormService: ContactFormService) { }

  ngOnInit() {
    setTimeout(() => {
      this.lazyLoad = true;
    }, 2000);
  }

  onSubmit() {
    this.contactFormService.submitForm(this.contactForm.value, '2')
    .subscribe(() => {
      this.subscribeSuccesfull = true;
    }, error => {
      console.log(error);
    });
  }

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get subject() { return this.contactForm.get('subject'); }
  get message() { return this.contactForm.get('message'); }

}
