<section class="contact" id="contact">
  <div class="contact-img" [lazyLoad]="'/assets/contact-header.jpg'">
    <div class="headline-inner">
      <h2>Ozvěte se nám</h2>
    </div>
  </div>
  <div class="container contact-content">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <h2>Kontakt</h2>
      </div>
      <div class="col-md-8 col-sm-12">
        <p>info@1pf.cz, +420 603 449 233</p>
        <p>Služská 1865/15, Praha 8, 182 00 , IČ: 26169410, DIČ: CZ26169410</p>
      </div>
    </div>

  </div>
  <div class="container form-headline-content">
    <h2>Nevadí, pokud máte jen hrubou představu, společně vše probereme a pokusíme se najít nejlepší řešení.</h2>
  </div>

</section>
