<header id="header">
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top">
    <mdb-navbar-brand>
      <a class="navbar-brand" [routerLink]="['/']">
        <img style="margin-top: 18px; margin-bottom: 17px;" alt="logo" width="91px" height="37" src="/assets/finlogo.svg">
        <!--img alt="logo" width="91px" height="37" src="/assets/logo.png">

        <p class="logo-label">software development company</p-->
      </a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto mt-lg-0">
        <li class="nav-item" mdbRippleRadius routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/sluzby']">{{'navigation-services' | translate}}</a>
        </li>
        <!--li class="nav-item" mdbRippleRadius routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/reference']">{{'navigation-reference' | translate}}</a>
        </li>
        <li class="nav-item" mdbRippleRadius routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/kariera']">{{'navigation-career' | translate}}</a>
        </li-->
        <li class="nav-item" mdbRippleRadius routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/kontakt']">{{'navigation-contact' | translate}}</a>
        </li>
      </ul>
      <!--ul class="navbar-nav ml-auto mt-lg-0">
        <li class="dropdown" dropdown>
          <span *ngIf="selectedLang === 'en'">
            <a class="nav-link dropdown-toggle" dropdownToggle mdbWavesEffect type="button" aria-haspopup="true">
              <span class="flag en"></span>ENG
              <span class="caret"></span>
            </a>
          </span>
          <span *ngIf="selectedLang === 'cs'">
            <a class="nav-link dropdown-toggle" dropdownToggle mdbWavesEffect type="button" aria-haspopup="true">
              <span class="flag cs"></span>CZ
              <span class="caret"></span>
            </a>
          </span>
          <div class="dropdown-menu dropdown dropdown-primary" role="menu">
            <div class="lang-item" *ngIf="selectedLang === 'cs'">
              <a (click)="selectLang('en')"><span class="flag en"></span>ENG</a>
            </div>
            <div class="lang-item" *ngIf="selectedLang === 'en'">
              <a (click)="selectLang('cs')"><span class="flag cs"></span>CZ</a>
            </div>
          </div>
        </li>
      </ul-->
    </links>
  </mdb-navbar>
</header>
