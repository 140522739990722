import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(private http: HttpClient) { }

  submitForm(data, formId) {
    const submitData = {
      apiKey: 'lll-l222-8989-ujuja-23',
      data: [
        { 'key': 'name', 'value': data.name },
        { 'key': 'email', 'value': data.email },
        { 'key': 'subject', 'value': data.subject },
        { 'key': 'message', 'value': data.message }
      ],
      formId: formId
    };
    return this.http.post('https://toms-cz.com/topportunity/api/v1/opportunity', submitData);
  }

}
